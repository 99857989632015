@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

*,
::after,
::before {
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  opacity: 1;
  overflow-y: scroll;
  margin: 0;
}

a {
  cursor: pointer;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
}

li {
  list-style: none;
}

h4 {
  font-family: "Poppins", sans-serif;
  font-size: 1.275rem;
  color: var(--bs-emphasis-color);
}

/* Layout for admin dashboard skeleton */

.wrapper {
  align-items: stretch;
  display: flex;
  width: 100%;
}

#sidebar {
  max-width: 264px;
  min-width: 264px;
  background: var(--bs-dark);
  transition: all 0.35s ease-in-out;
  position: fixed; /* Make the sidebar fixed */
  height: 100vh; /* Full height */
  overflow-y: auto; /* Enable scrolling for the sidebar */
  overflow-x: hidden;
}

.main {
  margin-left: 264px;
  width: calc(100% - 264px);
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto; /* Make the main content scrollable */
  transition: all 0.35s ease-in-out;
  background: var(--bs-dark-bg-subtle);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.main::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.main {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Sidebar Elements Style */

.sidebar-logo {
  padding: 1.15rem;
}

.sidebar-item:hover {
  background-color: rgb(76, 79, 81);
}
.try-side:hover {
  background-color: rgb(167, 183, 196);
}

.sidebar-logo a {
  color: #e9ecef;
  font-size: 1.15rem;
  font-weight: 600;
}

.sidebar-nav {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
  margin-left: 0;
}

.sidebar-header {
  color: #e9ecef;
  font-size: 1rem;
  padding: 1.5rem 1.5rem 0.375rem;
}

a.sidebar-link {
  padding: 0.625rem 1.625rem;
  color: #e9ecef;
  position: relative;
  display: block;
  font-size: 0.875rem;
}

.sidebar-link[data-bs-toggle="collapse"]::after {
  border: solid;
  border-width: 0 0.075rem 0.075rem 0;
  content: "";
  display: inline-block;
  padding: 2px;
  position: absolute;
  right: 1.5rem;
  top: 1.4rem;
  transform: rotate(-135deg);
  transition: all 0.2s ease-out;
}

.sidebar-link[data-bs-toggle="collapse"].collapsed::after {
  transform: rotate(45deg);
  transition: all 0.2s ease-out;
}

.avatar {
  height: 40px;
  width: 40px;
}

.navbar-expand .navbar-nav {
  margin-left: auto;
}

.content {
  flex: 1;
  max-width: 100vw;
  width: 100vw;
}

@media (min-width: 768px) {
  .content {
    max-width: auto;
    width: auto;
  }
}

.card {
  /* box-shadow: 0 0 0.875rem 0 rgba(34, 46, 60, 0.05); */
  margin-bottom: 24px;
  background-color: transparent;
}

.illustration {
  background-color: var(--bs-primary-bg-subtle);
  color: var(--bs-emphasis-color);
}

.illustration-img {
  max-width: 150px;
  width: 100%;
}

/* Sidebar Toggle */

#sidebar.collapsed {
  margin-left: -264px;
}

.main.collapsed {
  margin-left: 0;
  width: 100%;
}

/* Footer and Nav */

@media (max-width: 767.98px) {
  .js-sidebar {
    margin-left: -264px;
  }

  #sidebar.collapsed {
    margin-left: 0;
  }

  .navbar,
  footer {
    width: 100vw;
  }
}

.nav-link.dropdown-toggle::after {
  display: none !important; /* Hide the dropdown icon */
}

/* Theme Toggler */

.theme-toggle {
  position: fixed;
  top: 50%;
  transform: translateY(-65%);
  text-align: center;
  z-index: 10;
  right: 0;
  left: auto;
  border: none;
  background-color: var(--bs-body-color);
}

html[data-bs-theme="dark"] .theme-toggle .bi-sun,
html[data-bs-theme="light"] .theme-toggle .bi-moon {
  cursor: pointer;
  padding: 8px;
  display: block;
  font-size: 1.25rem;
  color: #fff;
}

html[data-bs-theme="dark"] .theme-toggle .bi-moon {
  display: none;
}

html[data-bs-theme="light"] .theme-toggle .bi-sun {
  display: none;
}

.navbar {
  position: sticky;
  top: 0;
  z-index: 1000; /* Ensure it stays above other content */
  /* Ensure it has a background color */
}

.dash-nav {
  background: var(--bs-dark-bg-subtle);
  transition: all 0.35s ease-in-out;
}

.profile-down {
  position: absolute;
  top: 100%; /* Position the dropdown right below the profile button */
  right: 0; /* Align to the right edge of the button */
  margin-top: 0.5rem;
  border-radius: 3px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  padding: 0;
  border: none;

  z-index: 1050; /* Ensure it stays above other content */
}

.profile-down.show {
  display: block;
}

.profile-down a {
  margin: 0.5rem 0;
}

.admin-category {
  font-size: 1.8rem;
  font-weight: 700;
}

/* for artist */

.for-img {
  height: 14rem;
}
.for-img img {
  height: 100%;
}

.add-admin {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
  width: 20rem;
}
.add-admin:focus {
  background-color: #eaf1f7 !important;
  color: black;
}

.admin-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5.5rem;
  width: 33rem;
}

.label-admin {
  font-size: 1.1rem;
  font-weight: 600;
}

.admin-list {
  height: 7rem;
}
.admin-list img {
  height: 100%;
}

.table-hyt {
  height: 8rem;
}

td {
  height: 100%;
}

.form-select {
  width: 10rem;
}

.x-button {
  background-color: rgba(97, 109, 120, 0.139);
  display: flex;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  left: -2rem;
  color: #e7f1f7;
  top: 1rem;
}

.x-butt {
  position: relative;
}

.button-update {
  margin-right: 6rem !important;
}
.button-cancel {
  position: relative;
}
.button-cancel button {
  position: absolute;
  right: 1rem;
  top: -2.4rem;
}
