@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Plus Jakarta Sans", sans-serif;
}

.img-resize {
  width: 100% !important;
  height: 100% !important;
}
.img-resize img {
  height: 100%;
  width: 100%;
}

.card-img-top {
  height: 100% !important;
  width: 100% !important;
  transition: opacity 0.8s ease-in-out, filter 0.8s ease-in-out;
}

.card-title {
  line-height: 1rem;
  height: 1.9rem;
}

.card-img-top:hover {
  opacity: 0.7;
  filter: grayscale(80%);
}

.sheet {
  height: 20vh;
}
.cardss {
  text-align: center;
  display: inline-block;
}

.card-img-top:not(:hover) {
  opacity: 9;
  filter: grayscale(30%);
}

/* WHEN HOVERED CHANGE COLOR */

.gallery-top {
  height: 100% !important;
  width: 100% !important;
  transition: filter 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.gallery-top:hover {
  filter: grayscale(0%);
  opacity: 0.7;
}

.gallery-top:not(:hover) {
  opacity: 9;
  filter: grayscale(0%);
}
/* Images with Bookmark */

.image-bookmark {
  position: relative;
}

.heart-bookmark {
  position: absolute;
  top: 1.5rem;
  left: 80%;
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(132, 136, 137, 0.395);
  border-radius: 50%;
  cursor: pointer;
  color: #353131;
}
.heart-bookmark i {
  font-size: 1rem;
  color: #353131;
  font-weight: bolder;
}

.bi-heart-fill {
  color: #b99292 !important;
}
.heart-bookmark i:hover {
  color: #de5f5f !important;
}

.hide {
  display: none;
}

.image-b .cards {
  height: 100% !important;
  width: 100% !important;
}
.card-body p {
  margin: 0;
}

.card {
  border: none !important;
}

.logo {
  height: 3rem;
}

.add-to-cart {
  text-decoration: none !important;
  color: #353131;
  margin-top: 5px;
}

.like-container {
  position: relative;
  display: inline-block;
  align-items: center;
}

.like-item {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #868f9793;
  top: -0.5rem;
  left: 50%;
}

.like-item span {
  font-size: 0.7rem;
  color: #f5f7f8;
}

.add-to-cart i {
  color: black;
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.nav-bg {
  height: 5rem;
}
.nav-user {
  height: 12.2vh;
}

.service {
  list-style: none;
  margin: 0;
  padding: 0;
}
.service li {
  margin-top: 0.5rem;
}

footer h3 {
  margin-top: 2rem;
  font-size: 1.3rem;
}

footer p {
  line-height: 2rem;
}
.form-footer {
  display: flex !important;
  margin-top: 2.5rem;
}

.footer-social i {
  margin-right: 1.5rem;
  font-size: 1.5rem;
}

.footer-social {
  margin-top: 2rem;
}

.address p {
  margin: 0;
}

.carousel-text {
  padding: 1.5rem;
}

.home-carousel-1 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8)),
    url("../public/heroImages/guy.png");
  height: 100%;
  background-position-x: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.home-carousel-7 {
  background-image: linear-gradient(
      rgba(50, 53, 54, 0.2),
      rgba(121, 134, 134, 0.8)
    ),
    url("../public/heroImages/1.png");
  height: 100%;
  background-position-x: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.home-carousel-8 {
  background-image: linear-gradient(
      rgba(50, 53, 54, 0.2),
      rgba(121, 134, 137, 0.8)
    ),
    url("../public/heroImages/2.png");
  height: 100%;
  background-position-x: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.home-carousel-9 {
  background-image: linear-gradient(
      rgba(50, 53, 54, 0.2),
      rgba(121, 134, 137, 0.8)
    ),
    url("../public/heroImages/3.png");
  height: 100%;
  background-position-x: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.home-carousel-1 .comment-text .row .col h1 {
  font-size: 4rem;
  font-weight: 700;
}

.home-carousel-2 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8)),
    url("../public/heroImages/white.png");
  height: 100%;
  background-position-x: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.home-carousel-2 .comment-text .row .col h1 {
  font-size: 4rem;
  font-weight: 700;
}

.home-carousel-3 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8)),
    url("../public/heroImages/sofa.png");
  height: 100%;
  background-position-x: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.home-carousel-3 .comment-text .row .col h1 {
  font-size: 4rem;
  font-weight: 700;
}

.home-section {
  padding: 0 !important;
}

.carousel-inner {
  height: 88vh;
}

.comment-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: rgb(253, 253, 253);
  text-align: center;
  font-size: 3rem;
}

#carouselExampleIndicators .carousel-indicators button {
  width: 20px !important;
  height: 20px !important;
  border: none !important;
  border-radius: 50% !important;
  background-color: rgba(255, 255, 255, 0.5) !important;
  margin: 0 5px !important;
}

#carouselExampleIndicators .carousel-indicators .active {
  background-color: #f3ecec !important;
}

#carouselExampleIndicators .carousel-indicators-1 button {
  width: 15px !important;
  height: 15px !important;
}
#carouselExampleIndicators .carousel-indicators-1 .active {
  background-color: #1f1d1d75 !important;
}

.team-img {
  height: 100%;
  width: 100%;
}

.author-info {
  display: flex;
  justify-content: center;
  align-items: center;
}

.featured {
  font-size: 1.6rem;
  font-weight: bold;
}

/* .quote_row {
  height: 20rem;
} */

.quote_img {
  height: 20rem;
  display: flex;
  justify-content: center;
}
.quote_img img {
  height: 100%;
}

.quote_info {
  text-align: center;
}

.quote_info p {
  font-size: 2.3rem;
  line-height: 2.3rem;
  margin-bottom: 1rem;
  font-family: "Caveat";
}

.quote_info h3 {
  font-size: 1.2rem;
  font-weight: 800;
}

.all-galleries {
  justify-content: center;
  display: flex;
  align-items: center;
  margin-bottom: 6rem;
}

/* .art-img-size {
  width: 23rem;
  height: 30rem;
} */
/* 
.art-img-size img {
  width: 100%;
  height: 100%;
} */

.art-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.art-img img {
  height: 50vh;
}

.art-price {
  height: 5rem;

  background-color: rgb(182, 189, 193);
  width: 39rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 5px;
}
.art-info {
  display: flex;
  align-items: end;
  line-height: 1.5rem;
}

.artist-img {
  height: 20rem;
  width: 20rem;
}
.artist-img img {
  height: 100%;
  width: 100%;
}

.gagi {
  width: 100%;
}

input {
  border: none;
  outline: none;
  background: inherit;
}

.input-bot {
  border-bottom: 1px solid rgb(174, 168, 168);
}

.rowsss {
  width: 100%;
}

textarea {
  border: none;
  outline: none;
}

.title {
  border-bottom: 1px solid rgb(174, 168, 168);
}

select {
  border: none;
  outline: none;
}

.label-of-arts {
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  font-weight: 600;
}

.selectArea {
  width: 100%;
}

.artist-text {
  font-family: "Manrope", sans-serif;
  font-weight: bold;
}

.label label {
  font-weight: 500;
  font-size: 1.1rem;
}

.row-bot {
  margin-bottom: 1.5rem !important;
}

.quotes {
  margin-top: 7rem;
  margin-bottom: 4rem;
}

.quotes-p {
  font-size: 2.5rem;
  font-family: "Caveat";
}

.quotes-r {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 4rem;
}

.selection {
  border: 1px solid rgb(182, 179, 179);
  height: 2.2rem;
  width: 14rem;
}

.artist-name {
  font-size: xx-small;
  font-style: italic;
}

.active {
  font-weight: bolder !important;
}

::placeholder {
  opacity: 50%;
}

.copyright {
  height: 4rem;
}

.footer-bg,
.nav-bg {
  background-color: rgb(199, 203, 205);
}

/* Spinner */

.loader {
  width: 8px;
  height: 40px;
  border-radius: 4px;
  display: block;
  margin: 20px auto;
  position: relative;
  background: currentColor;
  color: #353131;
  box-sizing: border-box;
  animation: animloader 0.3s 0.3s linear infinite alternate;
}

.loader::after,
.loader::before {
  content: "";
  width: 8px;
  height: 40px;
  border-radius: 4px;
  background: currentColor;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  box-sizing: border-box;
  animation: animloader 0.3s 0.45s linear infinite alternate;
}
.loader::before {
  left: -20px;
  animation-delay: 0s;
}

@keyframes animloader {
  0% {
    height: 58px;
  }
  100% {
    height: 8px;
  }
}

.spinner {
  height: 86vh;
  display: flex;
  align-items: center;
}

.line-shipping {
  line-height: 1.5rem;
}

.line-shipping h5 {
  font-weight: bolder;
}

.line-shipping p {
  font-size: 0.9rem;
}

.navbar-toggler-icon {
  font-size: 1.5rem;
  color: black;
}

.navbar-toggler {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  margin-bottom: 0.9rem !important;
}

button.btn-close {
  font-size: 1.3rem !important;
  margin-bottom: 1.1rem !important;
}

.offcanvas-end {
  width: 20rem !important;
}
.offcanvas-body {
  height: 3rem;
}

.banner {
  background-image: url("../public/heroImages/hand.png");
  height: 60vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.banner-2 {
  background-image: url("../public/heroImages/11.png");
  height: 50vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-top: 2rem;
}

.sorts {
  margin-bottom: 4rem;
}

.currency select {
  background-color: transparent !important;

  outline: none !important;
  box-shadow: none !important;
  width: 4.5rem;
  padding: 0;
  border-bottom: 1px solid black;
  margin-top: 0.5rem;
  margin-right: 1rem;
  height: 2rem;
  margin-bottom: 1rem;
}

.nav-item {
  font-size: 1.2rem;
}

.img-upload {
  /* background-color: aqua; */
  height: 18rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed rgb(181, 177, 177);
  border-radius: 20px;
}

.img-container {
  position: relative;
  /* background-color: blue; */
  width: 100%;
  height: 100%;
}

.img-container img {
  width: 100%;
  height: 100%;
}

.img-container i {
  position: absolute;
  left: 80%;

  color: rgb(238, 242, 244);
  top: 0.5rem;
  background-color: #525a5f81;
  height: 2rem;
  width: 2rem;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}

.label-upload-img {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid black; */
  /* background-color: aqua; */
  border-radius: 6px;
  background-color: #b8c3c9;
  cursor: pointer;
}

.img-view {
  /* background-color: bisque; */
  width: 15rem;
  height: 18rem;
  display: flex;
  justify-content: center;
  padding: 0.5rem;
}

.artist-section {
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.artist-solo-img {
  border: 2px dashed rgb(181, 177, 177);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  height: 100%;
}

.solo-artist {
  /* border: 1px solid rgb(69, 69, 75); */
  border-radius: 6px;
  background-color: #b8c3c9;
  font-size: 1rem;
  padding: 0 1rem;
  cursor: pointer;
}

.artist-box {
  /* border: 2px solid rgb(146, 9, 9); */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 10rem;
  /* background-color: aqua; */
}

.artist-image-container {
  width: 100%;
  height: 100%;
  /* background-color: aqua; */
  position: relative;
}
.artist-image-container img {
  width: 100%;
  height: 100%;
}
.artist-image-container i {
  position: absolute;
  left: 78%;
  background-color: #525a5f81;
  height: 1.9rem;
  width: 1.9rem;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 50%;
  top: 1.4rem;
  color: #e2e9ec;
  font-size: 1.6rem;
  cursor: pointer;
}

/* Login */
.create-account {
  color: #da682b;
}

.create-img {
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.create-img img {
  width: 100%;
  height: 100%;
}

.form-login {
  margin: 0 2rem;
  width: 90%;
}

label span {
  color: #da682b;
}

.forgot-text {
  font-size: 15px;
  font-weight: 600;
  color: #da682b;
}

.login-image {
  width: 100%;
  height: 35rem;
}

.login-image img {
  height: 100%;
  width: 100%;
}

.login-solo {
  width: 100%;
  background-color: white;
  padding: 0 !important;
  margin: 0 !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.login-field {
  border: 1px solid rgb(107, 110, 112);
  height: 2.5rem;
  margin-bottom: 2rem;
  border-radius: 5px;
}
.icon-login {
  height: 100%;
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgb(107, 110, 112);
}
.icon-login i {
  font-size: 1.5rem;
}
/* add to cart item */

.add-to-cart-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  border: 1px solid rgb(111, 108, 108);
  margin-top: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: 0.2s;
}

.add-to-cart-item:hover {
  background-color: rgb(111, 108, 108);
  color: #e1e9ed;
}

/* Shopping Cart */

.shop-cart {
  border: 1px solid rgb(111, 108, 108);
  padding: 0 !important;
  margin: 0;
}

.cart-items {
  display: flex;
  height: 3rem;
  align-items: center;
  background-color: #b8c3c9;
  padding: 0 !important;
  margin: 0;
}

.cart-items p {
  margin: 0;
}
.cart-shop-details {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-cart {
  width: 70%;
}

.img-qty {
  width: 20%;
}

.img-x {
  width: 10%;
  cursor: pointer;
}

.cart-image-art {
  height: 10rem;
  margin: 0.5rem;
}
.cart-image-art img {
  height: 100%;
}

.cart-info-art p {
  margin: 0;
}

.cart-info-art {
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: end;
  padding-bottom: 1rem;
}

.cart-summary {
  background-color: #b8c3c9;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-summary p {
  padding: 0 !important;
  margin: 0 !important;
  font-size: 1.3rem;
  font-weight: 600;
}

.subtotal {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.cart-check-out {
  height: 2.5rem;
  margin-bottom: 1rem;
  background-color: #b8c3c9;
  color: white;
  font-weight: bold;
  margin: 1.5rem;
  cursor: pointer;
}

.order-summary {
  border: 1px solid rgb(111, 108, 108);
}

.more-on-arts {
  margin-top: 7rem;
}

.slick-prev:before,
.slick-next:before {
  color: #9da4a7 !important;
  font-size: 1.5rem !important;
}

.form-control {
  border: none !important;
  background-color: inherit !important;
}

.form-control:focus {
  box-shadow: none !important;
  outline: none !important;
}

.form-column {
  height: 100%;
}

.carousel-column {
  padding: 0 !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: 70vh;
}

.form-log {
  padding: 0 1rem;
}

.create-bg {
  background-color: #f1f2f3;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.login-btn {
  width: 7rem;
  color: white;
}

.login-btn i {
  color: white;
}

.login-button button {
  height: 2.3rem;
  width: 8rem;
  font-size: 1rem;
}

.main {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.shadow-login {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.sell-admin {
  height: 70vh;
}

.profile-user-bg {
  background-image: url("../public/cover2.png");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 50vh;
  margin: 0 !important;
  padding: 0 !important;
}
.greet {
  font-size: 3rem;
  font-family: "Caveat";
}

/* .user-cover {
  display: flex;
  align-items: center;
} */
.user-input {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
}
.user-input:focus {
  background-color: #e4effa !important;
}

.user-info-form {
  background-color: #f0f4f436;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.nav-down {
  position: absolute;
  top: 100%; /* Position the dropdown right below the profile button */
  right: 0; /* Align to the right edge of the button */
  margin-top: -1rem !important;
  border-radius: 3px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  padding: 0;
  border: none;
  /* margin: 0 !important;
  padding-top: 0.5rem !important; */

  z-index: 1050; /* Ensure it stays above other content */
}

.nav-down button:hover {
  background-color: rgb(199, 203, 205);
  color: black;
}
.nav-down.show {
  display: block;
}
/* 
.nav-down a {
  margin: 0.5rem 0;
} */

.buttt {
  border-radius: 0.2rem !important;
}
